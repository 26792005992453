<template>
  <div id="Page-News-List" class="main-wrap">
		<div class="w850">
			<div class="title-h2">NEWS</div>
			<div class="title-h1">{{L("新闻")}}</div>
			<div class="mt-20"></div>
			<div class="list">
				<div v-for="item in list" v-bind:key="item.id" @click="doOpenContent(item)" class="item">
					<div class="title" v-if="$root.lang == 'CN'">{{L(item.title)}}</div>
					<div class="title" v-if="$root.lang == 'EN'">{{item.etitle||L(item.title)}}</div>
					<div class="mt-20"></div>
					<div class="date flex between middle">
						<div>{{L(item.keywords)}}</div>
						<div>{{$root.format('yyyy-MM-dd HH:mm:ss',new Date(item.createAt))}}</div>
					</div>
					<div class="mt-10"></div>
					<div class="line"></div>
					<div class="mt-20"></div>
					<div class="mt-20"></div>
					<div class="mt-20"></div>
				</div>
			</div>
		</div>
  </div>
</template>

<script>
export default {
  name: 'Page-News-List',
  components: {
    
  },
  data(){
    return {
			list:[],
			loading:false
    };
  },
  async mounted () {
		var result = await this.request.zd.get('NEWS');
		if(result && result.list) {
			this.list = result.list;
		} else {
			this.list = [];
		}
		
  },
  methods: {
		doOpenContent(item){
			if(item.isLink && item.linkUrl){
				window.open(item.linkUrl);
			} else {
				window.open("/#/News/Content/"+item.id);
			}
			
		}
  }
}
</script>

<style scoped lang="less">
.list{
	.item{
		.title{
			font-family: "Arvo","NotoSansCJKscB";
			font-size:18px;line-height: 1.2;word-break:break-all; word-wrap: break-word;cursor: pointer;
		}
		.date{color:#A0A0A0;font-size:14px;}
		.line{height:1px;background-color: #A0A0A0;}
	}
}
</style>